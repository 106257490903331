import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'

function getHome(link) {
  let verifiedLink
  if (link === 'home') {
    verifiedLink = '/'
    return verifiedLink
  }
  return link
}
const BurgerMenu = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems(
          filter: { name: { eq: "Main Navigation" } }
        ) {
          edges {
            node {
              name
              items {
                title
                url
                type
                object_slug
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Menu>
        <ul>
        {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
          <li key={item.object_slug}>
            <Link className="navbar-item" to={getHome(item.object_slug)}>
              {item.title}
            </Link>
          </li>
        ))}
        </ul>
      </Menu>
    )}
  />
)

export default BurgerMenu
