import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function getHome(link) {
  let verifiedLink
  if (link === 'index') {
    verifiedLink = '/'
    return verifiedLink
  }
  return link
}
const Navbar = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems(
          filter: { name: { eq: "Main Navigation" } }
        ) {
          edges {
            node {
              name
              items {
                title
                url
                type
                object_slug
              }
            }
          }
        }
        allFile(filter: { name: { eq: "logo_eom_blue_horizontal" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 260) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="grid-container">
        <div className="grid-x grid-margin-x navbar">
          <div className="cell small-12 medium-4">
              <Link to="/" className="navbar-item navbar-logo">

                  <Img
                    fluid={data.allFile.edges[0].node.childImageSharp.fluid}
                    alt="Efficiency of Movement logo gatsby image"
                    style={{ width: '150px' }}
                    critical
                  />

              </Link>
          </div>
          <nav className="cell small-12 medium-8 main-nav">
            <ul className="menu align-right ">
              {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                item => (
                  <li key={item.object_slug} className="show-for-large">
                    <Link
                      className="navbar-item"
                      to={getHome(item.object_slug)}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </nav>
        </div>
      </div>
    )}
  />
)

export default Navbar
