import React from 'react'
import Helmet from 'react-helmet'
import './all.scss'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  fab,
  faFacebookF,
  faTwitter,
  faSkype,
  faDribbble,
  faBehance,
  faGithub,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'
import {
  faUser,
  faCalendarAlt,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import Colophon from './Colophon'
import Navbar from './Navbar'
import BurgerMenu from './BurgerMenu'

library.add(
  faUser,
  fab,
  faFacebookF,
  faTwitter,
  faSkype,
  faDribbble,
  faBehance,
  faGithub,
  faInstagram,
  faInstagram,
  faLinkedinIn,
  faCalendarAlt,
  faPlus
)

const TemplateWrapper = ({ children }) => (
  <>
    <Helmet title="Home | Efficiency of Movement" >
      <html lang="en" />
      <meta name="description" content="Life under development" />
    </Helmet>
    <BurgerMenu className="show-for-medium-only" right />
    <Navbar />

    {children}
    <Colophon />
  </>
)

export default TemplateWrapper
