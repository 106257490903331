import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

const Colophon = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                colophon_left
                colophon_right
              }
            }
          }
        }
      }
    `}
    render={data => {
      const acfData = data.allWordpressAcfOptions.edges[0].node.options

      return (
        <div className="colophon">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6">
                <p>{acfData.colophon_left}</p>
              </div>
              <div className="cell small-12 medium-6">
                <p>{acfData.colophon_right}</p>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default Colophon
