import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function pad(n) {
  return n < 10 ? `0${n}` : n
}

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <div className="grid-container">
        <section className="grid-x grid-margin-x posts-list">
          <div className="cell">
            <h1 className="">{title}</h1>
          </div>
          {posts.map(({ node: post }, index) => (
            <div
              key={post.id}
              className="cell medium-10 medium-offset-1 posts-list-post"
            >
              <div className="card">
                <div className="grid-x">
                  <div className="cell medium-4 card-section text-center card-image">
                    {post.featured_media && (
                      <Img
                        alt={post.featured_media.alt_text}
                        fluid={
                          post.featured_media.localFile.childImageSharp.fluid
                        }
                      />
                    )}
                  </div>
                  <div className="cell medium-8 card-section card-copy">
                    <div className="card-divider card-title">
                      <h4 className="excerpt-title">{post.title}</h4>
                    </div>
                    <div className="card-section card-body">
                      {ReactHtmlParser(post.excerpt)}
                    </div>

                    <div className="card-section grid-x card-meta">
                      <span className="cell large-8 text-center post-meta">
                        <span className="grid-x">
                          <span className="author-meta">
                            <span className="icon-holder">
                              <FontAwesomeIcon icon={['fa', 'user']} />
                            </span>
                            <Link to={`author/${post.author.slug}`}>
                              {`By ${  post.author.name}`}
                            </Link>
                          </span>
                          {/*<span className="date-meta">*/}
                          {/*  <span className="icon-holder">*/}
                          {/*    <FontAwesomeIcon icon={['fa', 'calendar-alt']} />*/}
                          {/*  </span>*/}
                          {/*  {post.date}*/}
                          {/*</span>*/}
                        </span>
                      </span>
                      <span className="cell large-4 large-text-right read-more">
                        <Link className="button" to={`posts/${post.slug}`}>
                          Read More
                          <span className="icon-holder">
                            <FontAwesomeIcon icon={['fa', 'plus']} size="xs" />
                          </span>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="post-date">
                  <span>{post.date}</span>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date(formatString: "MMMM DD, YYYY")
    slug
    featured_media {
      alt_text
      localFile {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
